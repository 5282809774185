.search-form-vertical{
  background: #fff;
  padding: 15px;
}

.search-form-wrapper{
  .search-inner {
    background: #fff;
    padding: 30px;
    border: 1px solid #DDDDDD;
  }

  .btn-search{
    float:right;
    background: @brand-color;
    color: #fff;
    text-transform: uppercase;
    margin-top: 5px;
    padding: 10px 30px;
  }
}