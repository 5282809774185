html {
//  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-size: 15px;
  font-family: @font-main;
//  background-color: #f3f3f9;
  //height: 100%;
  line-height: 160%;
  min-height: 100%;
  margin:0;
  padding:0;
  image-rendering: -webkit-optimize-contrast;
  color: #646473;
}

h1, h2, h3, h4, h5, h6 {
  color: #262626;
  font-family: @font-title;
  font-weight: 400;
}
h1 .action, h2 .action, h3 .action, h4 .action, h5 .action, h6 .action {
  color: #E91E63;

  font-size: 12px;
  float: right;
  margin-top: 6px;

 }
h1 .action:hover, h2 .action:hover, h3 .action:hover, h4 .action:hover, h5 .action:hover, h6 .action:hover {
  text-decoration: none; }

h1 {
  font-size: 28px;
  margin-bottom: 20px;
  padding-bottom: 10px;
}

h1{
  position:relative;
  &:before{
    background-color: rgba(218,221,221,0.5);
    content: '';
    height: 1px;
    width: 222px;
    bottom: 0;
    position: absolute;
    left: 0;
    right: auto;
    display: block;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
  }

  &:after{
    background-color: @brand-color;
    content: '';
    height: 3px;
    width: 84px;
    bottom: -2px;
    position: absolute;
    left: 0;
    right: auto;
    -moz-transform: none;
    -webkit-transform: none;
    -o-transform: none;
    -ms-transform: none;
    transform: none;
    display: block;
  }
}

h2 {
  font-size: 24px;
  margin-bottom: 30px;
  position:relative;

}

h3 {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 400;
}

h4 {
  font-size: 16px;
  margin-bottom: 15px; }

h5 {
  font-size: 14px;
  margin-bottom: 10px; }

h6 {
  font-size: 12px;
  margin-bottom: 10px; }



a {
//  color: #EC407A;
  color: #000;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.06); }

blockquote {
  background-color: #fff;
  border: 1px solid #F5F5F5;
  color: #424242;
  font-style: italic;
  font-size: 22px;
  font-weight: 300;
  line-height: 30px;
  padding: 16px 16px 16px 80px;
  position: relative; }
blockquote:before {
  color: #EC407A;
  content: '\f10e';
  display: block;
  font-family: 'FontAwesome';
  font-size: 45px;
  left: 16px;
  position: absolute;
  top: 25px; }

ol {
  color: #616161;
  list-style-position: inside;
  padding: 0px; }
ol li {
  margin-bottom: 10px; }

ul {
  color: #616161;
  list-style-position: inside;
  padding: 0px; }
ul li {
  margin-bottom: 10px; }

#map-contact {
  height: 300px; }

.page-wrapper {
  //display: table;
  //height: 100%;
  //overflow: hidden;
  width: 100%; }

.main {
  display: table-row; }
.main a {
  transition: color .15s linear; }

.custom-space {
  background-color: #E91E63;
  color: #fff;
  margin-bottom: 30px; }

.custom-space-caption {
  background-color: #D81B60;
  font-size: 12px;
  padding: 2px 16px; }

.custom-space-inner {
  margin: 0px 0px 30px 0px;
  padding: 16px; }
.custom-space-inner h1 {
  margin: 0px 0px 10px 0px;
  font-size: 34px; }
.custom-space-inner p {
  font-size: 13px;
  font-weight: 500;
  margin: 0px; }

.module {
  background-color: #fff;
  border: 1px solid #EEEEEE;
  margin-bottom: 30px;
  display: table;
  width: 100%; }

.module-info {
  background-color: #FAFAFA;
  display: table-cell;
  font-size: 12px;
  padding: 16px;
  text-transform: uppercase; }
.module-info .fa {
  color: #616161;
  font-size: 18px;
  vertical-align: middle; }

.module-content {
  display: table-cell;
  padding: 16px; }
.module-content strong {
  color: #EC407A;
  font-weight: 600;
  text-transform: uppercase; }
.module-content span {
  font-weight: 600; }