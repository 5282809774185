.bx-wrapper .bx-next:after{
  font-family: "FontAwesome";
  content:"\f054";
  text-indent: 0 !important;
  font-size: 20px;
  color: #fff;
}

.bx-wrapper{
  margin-bottom: 10px;
}

#property-main-gallery-pager {
  text-align:center;

  a img {
    max-width: 60px;
  }
}
#property-main-gallery-pager a.active{
  opacity: .5;
}
