.site-main-nav{
  bottom:0;
  clear:both;
  margin-top: 30px;
  border-top: 1px solid @brand-grey;
  position: relative;
  &:before{
    content:' ';
    position: absolute;
    width: 80px;
    height: 4px;
    background: @brand-color;
    left:0;
    top:-2px;
  }
  ul{
    list-style:none;
    padding:0;
    margin:0;
    margin-left: -15px;

    &:before{
      content:" ";
      display:table;
    }

    li{
      position: relative;
      float:left;
      padding:0;
      margin:0;


      &:hover{
        > a {
          //background: @site-nav-hover-bg;
          //color: @site-nav-hover-color;
          opacity:.8;
        }

        > .sub-menu {
          visibility: visible;
          opacity: 1;
          top: 100%;
        }
      }

      > a{
        display: block;
        padding: 20px 12px;
        background: @site-nav-bg;
        color: #000;
        text-transform: uppercase;
        text-decoration: none;
        //font-family: @font-title;
        font-size: @site-nav-fontsize;
        font-weight: @site-nav-fontweight;
        font-family: @font-title;

        &.active{
          background-color: @site-nav-active-bg;
        //  color: @site-nav-hover-color;
        }
      }

//      &:last-child{
//        a{
//          margin-right:0;
//          padding-right:0;
//        }
//      }


      .sub-menu{
        visibility:hidden;
        opacity:0;
        //  display:block;
        position: absolute;
        top: 130%;
        left: 0;
        z-index: 1000;
        padding: 12px;
        background: @site-nav-submenu-bg;
        width: 240px;
        transition: all 0.25s;
        //transform: translate3d(0,15px,0);

        &:before{
          content: " ";
          position: absolute;
          width: 100%;
          height: 10px;
          top: -10px;
          opacity: 0;
        }
        li{
          float: none;
        }

        a{
          float:none;
          font-size: @site-nav-submenu-fontsize;
          padding: 5px 15px;
          color: @site-nav-submenu-color;

        }
      }


    }
  }
}

.read-more{
  display:inline-block;
  padding: 5px 20px;
  color: #333;
  border: 1px solid #333;

  &.rm-negative{
    color: #fff;
    border: 1px solid #fff;
  }

  &:hover{
    color: @brand-color !important;
    border-color: @brand-color;
    text-decoration: none;
  }
}

.mobile-trigger i{
  font-size: 18px;
  color: #000;
}

.homepage-menu .mobile-trigger i{
  color: #fff;
}

#mm-mobilenav {
  .mm-listview {

    .sub-menu {
      display: block;
      position: relative;
      background: none;
      width: auto;
      visibility: visible;
      opacity:1;
      padding:0;
    }

    li {
      float: none !important;

      a {

        width: 100%;
        float: none;
        text-align: center;
      }
    }
  }
}
#mobile-header{
  position: absolute;
  left: 20px;
  top: 20px;
  z-index:100;
}

ul.menu{
  list-style: none;
  padding:0;
  margin:0;

  &:before{
    content:" ";
    display:table;
  }

  li {
    position: relative;
    float: left;
    padding:0 10px;
    border-right: 1px solid #fff;

    &:last-of-type{
      border-right:none;
      padding-right: 0;
    }
  }
}

.footer-menu a{
  color: #fff;
}

.mm-page {
  box-sizing: border-box;
  position: relative;
 // display: table;
 // height: 100%;
 // overflow: hidden;
  width: 100%;
}

@media(min-width: @screen-lg){
  #mobile-header{
    display: none;
  }
}

@media(max-width: @screen-lg){
//  .site-main-nav{
//    float:none;
//    margin:0 auto;
//    display: block;
//
//    ul{
//      margin:0 auto;
//      li{
//        a{
//          padding: 10px 10px;
//          font-size: 16px;
//        }
//      }
//    }
//  }
}

.pagination{
  float:right;
  .active span{
    background-color: @brand-color;
    border-color: @brand-color;
  }
}

.btn-red{
  background: @brand-color;
  color: #fff;
  padding: 15px 25px;
  &:hover{
    background: fade(@brand-color, 80%);
    color: #fff;
  }
}

.btn-login{
  margin:-25px 0;
}