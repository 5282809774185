//.bx-wrapper img{
//  display:block;
//  max-width: 100%;
//}
//
//.bx-wrapper div{
//  display:block;
//  max-width: 100%;
//}


.properties-slider-wrapper{
  height: 550px;
  overflow:hidden;
}

.property-slider-item{
  min-height: 550px;
 background-color: #fff;

  .property-slider-info{
    width: 450px;
    padding: 20px 20px 30px 20px;
    background: fadeOut(#000,30%);
    color: #fff;
    margin-top: 100px;
    position:relative;


    .property-slider-delim{
      margin:20px 0;
      height:1px;
      border-bottom: 1px solid rgba(255,255,255,.5);
      clear:both;
    }

    h3{
      font-size: 20px;
      color: #fff;
      text-decoration: none;
    //  overflow: hidden;
    //  text-overflow: ellipsis;
    //  white-space: nowrap;
      margin-bottom: 10px;
      width: 100%;
      a{
        color: #fff;
        text-decoration: none;
      }
    }

    .property-box-meta-citem{
      color: #fff;
      i{
        color: #fff;
      }
    }

    .property-slider-price{
      font-size: 22px;
      color: #fff;
      font-weight: 500;
      float: left;
    }

    .property-slider-view{
      color: #fff;
      float: right;
    }
  }
}
